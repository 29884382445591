<template>
  <v-col>
    <v-list>
      <v-list-item
        v-for="(network, networkIndex) in networks"
        :key="networkIndex"
      >
        <v-list-item-content>
          {{ network.networkCode }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('tools')

export default {
  name: 'NetworkList',
  computed: {
    ...mapGetters(['organizations']),
  },
  networks() {
    return this.organizations.filter((org) => org.networkCode)
  },
}
</script>
